var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
//@ts-nocheck
import { apiFetch } from './api-fetch.js';
/** @param {UserSignup} user */
export function signupUser(user) {
    return apiFetch(`/v1/users/signup`, 'POST', user).then((r) => r.json());
}
/**
 * @description requires superuser permissions
 * */
export function getAllUsers() {
    // TODO: This is the actual endpoint, wire it up when it's ready
    return apiFetch(`/v1/users`, 'GET').then((r) => r.json());
}
/**
 * @param {{email: string, password: string}} login
 * @returns {Promise<LoginResponse>}
 * */
export function loginUser({ email, password, deviceFingerprint, totpCode }) {
    return apiFetch(`/v1/authentication/token`, 'POST', {
        email,
        password,
        deviceFingerprint,
        totpCode,
    })
        .then((r) => {
        if (!r.ok) {
            return r.json().then((errorData) => {
                throw new Error(errorData.message || 'Login failed');
            });
        }
        return r.json();
    })
        .then((data) => {
        if (data.message === 'New device detected. A verification code has been sent to your email.') {
            window.dispatchEvent(new CustomEvent('new-device-detected', { detail: { message: data.message } }));
            window.dispatchEvent(new CustomEvent('new-device-detected', { detail: { message: data.message } }));
            throw new Error('TOTP code required');
        }
        return data;
    })
        .catch((error) => {
        console.error('Login error!', error);
        if (error.message === 'totp code must be provided to verify device') {
            window.dispatchEvent(new CustomEvent('new-device-detected', { detail: { message: error.message } }));
            throw new Error('TOTP code required');
        }
        throw error;
    });
}
/**
 * @param {string} userId
 * @returns
 */
export function getUserById(userId) {
    return apiFetch(`/v1/user?userid=${userId}`, 'GET').then((r) => r.json());
}
/**
 * @param {string} userId
 * @returns {Promise<User>}
 * */
export function getUser(userId) {
    return apiFetch(`/v1/user?userid=${userId}`, 'GET').then((r) => r.json());
}
/**
 * @param {User} user
 * @returns {Promise<Response>}
 */
export function updateUser(user) {
    return apiFetch('/v1/users/update', 'PUT', user);
}
/** @param {string} userId */
export function deleteUser(userId) {
    return apiFetch(`/v1/user/delete?userid=${userId}`, 'DELETE');
}
// approve user /v1/users/admin/approve
/** @param {string} userId */
export function approveUser(userId) {
    return apiFetch(`/v1/users/admin/approve?userid=${userId}`, 'POST');
}
// set admin /v1/users/admin/setadmin
/** @param {string} userId */
export function setAdmin(userId) {
    return apiFetch(`/v1/users/admin/make-admin?userid=${userId}`, 'POST');
}
/**
 * @param {{email: string }} resetArgs
 **/
export function resetPassword({ email }) {
    return apiFetch(`/v1/users/password-reset`, 'POST', {
        email,
    });
}
/** @param {{email: User['email'], password: string, token: string}} email */
export function newPassword({ email, password, token: emailToken }) {
    return apiFetch(`/v1/users/new-password`, 'PUT', { email, password, emailToken });
}
export function getUserRoles() {
    return apiFetch(`/v1/user/roles`, 'GET')
        .then((r) => r.json())
        .then((json) => {
        return json;
    });
}
/**
 * @param {string} [userId] - Optional user ID. If not provided, fetches permissions for the current user.
 * @returns {Promise<Array<string>>}
 */
export function getUserPermissions(userId) {
    const url = userId ? `/v1/user/permissions?id=${userId}` : '/v1/user/permissions';
    return apiFetch(url, 'GET')
        .then((r) => r.json())
        .then((json) => {
        return json;
    });
}
export function addRoleToUser(userId, roleId) {
    return __awaiter(this, void 0, void 0, function* () {
        return apiFetch('/v1/user/roles/add', 'POST', {
            userId,
            roleId,
        });
    });
}
